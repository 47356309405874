<template>
  <div class="_content">
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <div class="maintab">
          <a-tabs default-active-key="1" tab-position="left">
            <a-tab-pane v-for="(item, i) in data" :key="i" :tab="item.category">
              <div class="subtab">
                <a-tabs
                  :default-active-key="item.children[0]"
                  tab-position="top"
                >
                  <a-tab-pane v-for="(items, ii) in item.children" :key="ii">
                    <span slot="tab"
                      ><a-icon type="folder" /> {{ items.category }}</span
                    >
                    <ImagesGallery :propsId="items.id" />
                  </a-tab-pane>
                </a-tabs>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ImagesGallery from "@/components/galeri/gallery";
export default {
  components: {
    ImagesGallery,
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.fetchMenu();
  },
  methods: {
    fetchMenu() {
      this.$store.dispatch("GetMenuTabGalleries").then((response) => {
        this.data = response.data.data;
      });
    },
  },
};
</script>
